// Here you can add other styles

html {
    font-size: 11pt;
}

.body {
    font-family: Lato;
}

.c-sidebar {
    background-color: #252d34 !important;
}

.fc-event {
    cursor: pointer;
}

.ml-10 {
    margin-left: 6rem;
}

.float-center {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.divider {
    height: 0.8rem;
}

.divider-sm {
    height: 0.4rem;
}

.divider-xs {
    height: 0.2rem;
}

.divider-lg {
    height: 1.6rem;
}

.divider-xl {
    height: 3.2rem;
}

.larger {
    font-size: larger;
}

.x-large {
    font-size: x-large;
}

.small {
    font-size: small;
}

.bold {
    font-weight: bold;
}

.capitalize {
    text-transform: capitalize;
}

.clickable {
    cursor: pointer;
}

.truncate-text {
    max-width: 100%;
    width: 100%;
    word-break: break-all;
    white-space: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-clamp: 2;
    text-align: right;
}

.hoverable:hover {
    text-decoration: none;
    background-color: #ebedef;
}

.max-width-body {
    max-width: 1366px;
}

.table-header-border-none th {
    border-top: none !important;
}

.show-line-breaks {
    white-space: pre-line;
}